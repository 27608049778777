import Button from './layout/button';
import { useApp } from '../app.hook';
import { classNames } from '../utils/functions';
import { useMemo } from 'react';
import { Produto } from '../app.models';
import { ShoppingCartIcon } from '@heroicons/react/outline';

interface Props {
  action: () => void;
  produto: Produto;
  disabled?: boolean;
  isIcon?: boolean;
}

export default function ButtonComprar({
  action,
  produto,
  disabled = false,
  isIcon = false,
}: Props) {
  const { empresa } = useApp();

  const btn = useMemo(
    () => <Button title="Comprar" onClick={action} disabled={disabled} />,
    [action, disabled]
  );

  const btnIcon = useMemo(
    () => (
      <button
        type="button"
        title="Comprar"
        className="bg-primary1 p-1 m-1 text-default-white rounded-full"
        onClick={action}
      >
        <ShoppingCartIcon className="h-4 w-4" />
      </button>
    ),
    [action]
  );

  if (empresa?.permitirVendaSemSaldo) {
    return btn;
  }

  const isProductAvailable =
    produto?.saldo?.saldo > 0 || produto?.qtdeDisponivel > 0;

  if (isProductAvailable) {
    if (isIcon) {
      return btnIcon;
    }

    return btn;
  } else {
    if (isIcon) {
      return (
        <button
          type="button"
          title="Comprar"
          className="bg-slate-500 p-1 m-1 text-default-white rounded-full"
          onClick={() => null}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 7M7 13l-1.4 5M17 13l1.4 5M9 21h6m-6-4h6"
            />
          </svg>
        </button>
      );
    }
    return (
      <button
        className={classNames(
          'bg-slate-600 text-white',
          'disabled:bg-gray-500 disabled:text-white',
          'flex items-center rounded-2xl',
          'space-x-1',
          'py-2 px-4',
          'drop-shadow-md'
        )}
        onClick={() => null}
        disabled={disabled}
      >
        Sem estoque
      </button>
    );
  }
}
