import { Marca, Modelo, VeiculosByProduct } from '../../app.models';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { classNames } from '../../utils/functions';
import { productUtils } from '../../utils/product';

interface VeiculoCompativelProps {
  key: number;
  veiculo: VeiculosByProduct;
  marcas: Marca[];
  modelos: Modelo[];
  onClick?: (veiculo: VeiculosByProduct) => void;
}

export default function ShowVeiculoCompativelCard({
  key,
  veiculo,
  marcas,
  modelos,
  onClick,
}: VeiculoCompativelProps) {
  return (
    <div
      key={key}
      className={classNames(
        'h-36 flex flex-col px-2 group bg-white cursor-pointer hover:scale-105 hover:shadow-md hover:border hover:border-primary1 hover:border-opacity-50 transition-all duration-300 ease-in-out'
      )}
      onClick={() => {
        onClick?.(veiculo);
      }}
    >
      <div className="flex flex-col  overflow-hidden">
        <div className="relative z-10">
          <LazyLoadingImage
            classNames={['w-6 h-6']}
            alt={veiculo.marcaDesc}
            height={30}
            width={30}
            src={
              marcas.find((m) => m.id === veiculo.marcaId)?.images[0].img ||
              productUtils.getImagePlaceholder({ size: 100 })
            }
            efect="blur"
          />
        </div>
        <div className="relative -top-4 z-0">
          <LazyLoadingImage
            classNames={['mx-auto']}
            alt={veiculo.modeloDesc}
            height={80}
            width={100}
            src={
              modelos.find((m) => m.id === veiculo.modeloId)?.images[0].img ||
              productUtils.getImagePlaceholder({ size: 100 })
            }
            efect="blur"
          />
        </div>
      </div>
      <div className="text-[0.6em] w-full text-center">
        Ano: {veiculo.anoI} - {veiculo.anoF}
      </div>
      <div className="text-[0.7em] text-center line-clamp-2 group-hover:line-clamp-4 w-full font-bold text-slate-700">
        {veiculo.modeloDesc}
      </div>
    </div>
  );
}
