import { Dialog, Transition } from '@headlessui/react';
import { TrendingDownIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
// import { toast } from 'react-toastify';
import { Cart, Produto } from '../../app.models';
import InputQtde from '../../components/inputQtde';
import { Progress } from '../../components/progress';
import { replaceImgWithError } from '../../utils/functions';
import { useCart } from '../cart/cart.hook';
import { addItemCart, getCart } from '../cart/cart.service';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import ButtonComprar from '../../components/buttonComprar';
import { useApp } from '../../app.hook';
import { productUtils } from '../../utils/product';
// import ModalSelectCard from '../myCarts/modalSelectCard';

interface ModalProductProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  product: Produto | undefined;
  onClose?: () => void;
}

export default function ModalProduct(props: ModalProductProps) {
  const { isXs } = useApp();
  const { currentCart, loadCart } = useCart();
  const btnRef = useRef<any>(null);

  const [isIncludeCart, setIsIncludeCart] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [qtde, setQtde] = useState<number>(1);
  // const [openModalSelectCart, setOpenModalSelectCart] =
  //   useState<boolean>(false);

  // useEffect(() => {
  //   setDisabled(false);
  // }, []);

  const closeModal = useCallback(() => {
    props.setIsOpen(false);
    props.onClose && props.onClose();
  }, [props]);

  const buy = useCallback(
    async (productId: number) => {
      setDisabled(true);
      const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

      if (verificaSituacao === 0) {
        addItemCart(currentCart.id, productId, qtde, currentCart.updated_at)
          .then((data) => {
            loadCart(data as Cart);
            // setDisabled(false);
            // closeModal();
            // toast('Carrinho atualizado');
          })
          .finally(() => {
            closeModal();
            setDisabled(false);
          });
      }
    },
    [currentCart.id, currentCart.updated_at, loadCart, qtde, closeModal]
  );

  useEffect(() => {
    if (props.product?.id && currentCart.produtos) {
      const item = currentCart.produtos.find(
        (p) => p.produto.id === props.product?.id
      );
      if (item) {
        setIsIncludeCart(true);
        setQtde(item.qtde);
      } else {
        setIsIncludeCart(false);
        setQtde(1);
      }
    }
  }, [currentCart.produtos, props.product?.id]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && btnRef.current) {
        btnRef.current.click();
      }

      if (e.key === 'ArrowDown') {
        if (qtde < 2) return;
        setQtde(qtde - 1);
      }

      if (e.key === 'ArrowUp') {
        setQtde(qtde + 1);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [buy, closeModal, props.product, qtde]);

  if (!props?.product) return <></>;

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                isXs
                  ? 'inline-block w-[90vw] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300'
                  : 'inline-block w-[60vw] max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300'
              }
            >
              <Dialog.Title as="div" className="flex justify-between">
                <span className="text-lg font-bold leading-6 text-gray-900 font-display line-clamp-2">
                  {props.product?.descricaoSite}
                </span>
                <button onClick={closeModal} className="hover:text-gray-400">
                  <XIcon className="w-5 h-5" />
                </button>
              </Dialog.Title>
              <div className="mt-2 flex font-display">
                <div className="w-2/12">
                  <img
                    style={{
                      width: '100px',
                      height: '60px',
                      objectFit: 'contain',
                      borderRadius: '0.375rem',
                      margin: '0 auto',
                    }}
                    src={props.product?.images[0].small}
                    alt={props.product?.descricaoSite}
                    onError={(e) =>
                      replaceImgWithError(
                        e,
                        productUtils.getImagePlaceholder({ size: 160 })
                      )
                    }
                  />
                </div>
                <div className="flex justify-between w-6/12">
                  <div className="flex flex-col ">
                    <span className=" text-xs">
                      Ref:{' '}
                      <span className="font-bold">
                        {props.product?.referencia || 'N/D'}
                      </span>
                    </span>
                    <span className=" text-xs">
                      Código:{' '}
                      <span className="font-bold">
                        {props.product?.codigoSgi}
                      </span>
                    </span>
                  </div>
                  {props.product?.desconto > 0 && (
                    <span className="w-10 inset-1 skew-y-3 bg-primary1 flex flex-col justify-center items-center py-0">
                      <span className="text-default-white text-xs  font-bold">
                        {props.product?.preco} %
                      </span>
                      <TrendingDownIcon className="w-4 h-4 text-default-white " />
                    </span>
                  )}
                </div>

                <div className="flex flex-col w-6/12">
                  <span className=" text-xs">Categoria: </span>
                  <span className="font-bold text-xs">
                    {props.product?.categoria?.descricao}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-end mt-5 font-display">
                <div className="">
                  <h1 className="text-xs h-0">Preço</h1>
                  {props.product?.isPromocao ? (
                    <div className="flex flex-col my-1">
                      <div className="flex flex-col items-end">
                        <span className="flex space-x-2 md:text-sm lg:text-md line-through text-gray-400">
                          {/* <span>{parseCurrency(props.product?.preco)}</span> */}
                          <ExibirPrecoConsumidorFinal
                            item={props.product}
                            field="preco"
                            fieldConsumidorFinal="precoConsumidorFinal"
                          />
                        </span>
                        <span className="flex space-x-2 text-md md:text-md lg:text-2xl text-primary1 font-bold">
                          {/* <span>
                            {parseCurrency(props.product?.precoPromo)}
                          </span> */}
                          <ExibirPrecoConsumidorFinal
                            item={props.product}
                            field="precoPromo"
                            fieldConsumidorFinal="precoPromoConsumidorFinal"
                          />
                        </span>
                      </div>
                      {props.product?.promocao && (
                        <div className="flex justify-center">
                          <Progress
                            qtdeTotal={props.product?.promocao.qtdeEmPromocao}
                            qtdeMovimentada={
                              props.product?.promocao.qtdeMovimentada
                            }
                            qtdeDisponivel={
                              props.product?.promocao.qtdeDisponivel
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center my-0">
                      <div className="flex my-3 justify-end">
                        <span className="flex space-x-2 font-bold text-xl">
                          {/* <span>
                            {parseCurrency(props.product?.precoPromo)}
                          </span> */}
                          <ExibirPrecoConsumidorFinal
                            item={props.product}
                            field="precoPromo"
                            fieldConsumidorFinal="precoPromoConsumidorFinal"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={isXs ? 'flex flex-col gap-2' : 'flex space-x-3'}
                >
                  <InputQtde
                    value={qtde}
                    changeValue={(qtde) => setQtde(qtde)}
                  />
                  {isIncludeCart && (
                    <div className="text-amber-800 animate-pulse bg-amber-100 rounded-xl p-3">
                      Incluso no Carrinho
                    </div>
                  )}
                  {/* <Button
                    ref={btnRef}
                    title="Comprar"
                    disabled={disabled}
                    onClick={() => {
                      setDisabled(true);
                      props.product?.id && buy(props.product?.id);
                      // closeModal();
                    }}
                  /> */}
                  <ButtonComprar
                    produto={props.product}
                    action={() => {
                      props.product?.id && buy(props.product?.id);
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
