import { Produto } from '../app.models';

export const productUtils = {
  sortByOrderType: (products: Produto[], orderType: 'alfa' | 'num') => {
    if (!products?.length) return [];

    const orderedList = [...products];

    orderedList?.sort((a, b) => {
      const balanceA = a?.saldo?.saldo || 0;
      const balanceB = b?.saldo?.saldo || 0;
      const shouldUseDefaultOrder =
        (balanceA > 0 && balanceB > 0) || (balanceA === 0 && balanceB === 0);

      if (shouldUseDefaultOrder) {
        return orderType === 'alfa'
          ? a?.descricaoSite.localeCompare(b?.descricaoSite)
          : parseInt(a?.codigoSgi) - parseInt(b?.codigoSgi);
      }

      return balanceB - balanceA;
    });

    return orderedList;
  },

  getImagePlaceholder: ({ size }: { size: number }) => {
    const baseUrl = 'https://placehold.co';
    return `${baseUrl}/${size}?text=Sem+Imagem`;
  },

  getDefaultImagePlaceholders: () => {
    return {
      small: productUtils.getImagePlaceholder({ size: 100 }),
      medium: productUtils.getImagePlaceholder({ size: 300 }),
      big: productUtils.getImagePlaceholder({ size: 600 }),
    };
  },
};
