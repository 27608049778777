import {
  BanIcon,
  CheckCircleIcon,
  ShoppingCartIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from '@heroicons/react/outline';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApp } from '../../app.hook';
import { Cart, Comprador, NewCartProduto, ProdutoCart } from '../../app.models';
import {
  getCryptoLocalStorage,
  setCryptoLocalStorage,
} from '../../app.service';
import { LOCAL_STORAGE_USER } from '../../app.settings';
import {
  addItemCart,
  delItemCart,
  getCart,
  updateCart,
  newCart as nCart,
  clearCart as esvaziarCarrinho,
  getClienteById,
} from './cart.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import { convertImages } from '../../utils/functions';
import { toast } from 'react-toastify';
import { productUtils } from '../../utils/product';

const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;

interface ICartStatus {
  situacao: string;
  id: number;
  icone: React.ReactElement;
  className: string;
}

interface IProvider {
  children: React.ReactNode;
}

interface IContext {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  currentCart: Cart;
  loadCart: (newCart?: Cart) => Promise<void>;
  upsetItemCart: (itemId: number, qtde: number) => void;
  deleteItemCart: (itemId: number) => void;
  cartStatus: ICartStatus[];
  updCart: (comprador: Comprador | null) => void;
  updBalcaoComprador: (data: Record<string, any>) => void;
  setCurrentCart: (cartId: number) => void;
  newCart: (produto?: NewCartProduto) => Promise<void>;
  cleanCart: () => void;
  renderCart: number;
}
const Context = createContext<IContext>({} as IContext);

const CartProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const { currentUser, currentClientAtivo } = useApp();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentCart, _setCurrentCart] = useState<Cart>({} as Cart);
  const [oldCart, setOldCart] = useState<Cart>({} as Cart);

  useEffect(() => {
    // if (currentUser.carrinhoAtual) loadCart();
    if (!currentUser?.carrinhoAtual) {
      newCart();
    } else {
      if (currentUser.carrinhoAtual !== currentCart.id) {
        loadCart();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.carrinhoAtual]);

  function updateTabelaLocalStorage(newTabelaPreco: number) {
    const localStorageUser = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);
    const newLocalStorageUser = {
      ...localStorageUser,
      tabelaId: newTabelaPreco,
    };
    setCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI, newLocalStorageUser);
    // setTabelaUpdateCount();
  }

  const loadCart = async (newCart = {} as Cart) => {
    setLoading(true);
    if (newCart.id) {
      try {
        const formatedCart = {
          ...newCart,
          produtos: newCart.produtos.map((pc: ProdutoCart) => ({
            ...pc,
            produto: {
              ...pc.produto,
              images:
                pc.produto.images.length > 0
                  ? pc.produto.images[0].small
                    ? pc.produto.images
                    : convertImages(
                        pc.produto.images,
                        pc.produto.id,
                        'produtos'
                      )
                  : [productUtils.getDefaultImagePlaceholders()],
            },
          })),
        };
        _setCurrentCart(formatedCart);
        updateTabelaLocalStorage(formatedCart.tabelaPrecos.id);
      } catch (e) {
        console.log('🚀 ~ file: cart.hook.tsx ~ line 79 ~ loadCart ~ e', e);
      } finally {
        setLoading(false);
      }
      // _setCurrentCart(newCart);
    } else if (
      currentClientAtivo &&
      currentClientAtivo?.id !== currentCart?.cliente?.id
    ) {
      const cliente = await getClienteById(currentClientAtivo.id);
      await (getCart(currentCart.id) as Promise<Cart>).then((cart) => {
        if (cart.cliente.id === cliente.id) {
          return;
        }
        updateCart(cart.id, {
          carrinho: {
            id: cart.id,
            updated_at: cart.updated_at,
            cliente: cliente?.id,
            tabelaPrecos: cliente.tabelaId,
          },
        } as unknown as Cart)
          .then((_cart) => {
            _setCurrentCart(_cart);
          })
          .finally(() => {
            updateTabelaLocalStorage(cliente.tabelaId);
            setLoading(false);
          });
      });
    } else {
      // load inicial
      const currCart = currentCart.id || currentUser.carrinhoAtual;
      if (currCart === currentCart.id) {
        setLoading(false);
        return;
      } else {
        await getCart(currCart)
          .then((_cart) => {
            _setCurrentCart(_cart);
            updateTabelaLocalStorage(_cart.cliente.tabelaId);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    // console.log(getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI));
  };

  // adicionar tabela padrao do carrinho no localStorage
  const newCart = async () => {
    const ncart = await nCart();
    await new Promise((t) => setTimeout(t, 100));
    setCurrentCart(ncart.id);
    setRenderCart((s) => s + 1);
    updateTabelaLocalStorage(ncart.tabelaPrecos.id);
  };

  const cleanCart = async () => {
    await esvaziarCarrinho(currentCart.id, currentCart.updated_at);
    await new Promise((t) => setTimeout(t, 100));
    setCurrentCart(currentCart.id);
    setRenderCart((s) => s + 1);
  };

  const upsetItemCart = (itemId: number, qtde: number) => {
    setLoading(true);
    setOldCart(currentCart);
    addItemCart(currentCart.id, itemId, qtde, currentCart.updated_at)
      .then((cart) => {
        _setCurrentCart(cart);
        setRenderCart((s) => s + 1);
      })
      .catch((e) => {
        _setCurrentCart(oldCart);
        throw new Error(e);
        // toast.error('Erro ao atualizar seu carrinho!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteItemCart = (itemId: number) => {
    setLoading(true);
    setOldCart(currentCart);
    delItemCart(currentCart.id, itemId, currentCart.updated_at)
      .then((cart) => {
        _setCurrentCart(cart);
        setRenderCart((s) => s + 1);
      })
      .catch(() => {
        _setCurrentCart(oldCart);
        // toast.error('Erro ao atualizar seu carrinho!');
      })
      .finally(() => setLoading(false));
  };

  const updCart = (comprador: Comprador | null) => {
    // if (comprador) {
    updateCart(currentCart.id, {
      carrinho: {
        id: currentCart.id,
        updated_at: currentCart.updated_at,
        comprador: comprador?.id || null,
        cliente: clienteId,
        tabelaPrecos: tabelaId,
      },
    } as unknown as Cart).then((card) => {
      _setCurrentCart(card);
      // loadCart();
      toast.success('Dados atualizados com sucesso!');
    });
    // } else {
    //   updateCart(currentCart.id, {
    //     carrinho: {
    //       id: currentCart.id,
    //       updated_at: currentCart.updated_at,
    //       comprador: null,
    //       cliente: clienteId,
    //       tabelaPrecos: tabelaId,
    //     },
    //   } as unknown as Cart).then(loadCart);
    // }
  };

  const updBalcaoComprador = (data: Record<string, any>) => {
    updateCart(currentCart.id, {
      carrinho: {
        id: currentCart.id,
        updated_at: currentCart.updated_at,
        contatoNome: data.contatoNome,
        contatoFone: data.contatoFone,
        cliente: clienteId,
        tabelaPrecos: tabelaId,
      },
    } as unknown as Cart).then((data) => {
      loadCart(data);
      toast.success('Dados atualizados com sucesso!');
      // window.location.reload();
    });
  };

  const setCurrentCart = async (cartId: number) => {
    setLoading(true);
    const _cart = await getCart(cartId);
    _setCurrentCart(_cart);
    const user = getCryptoLocalStorage(LOCAL_STORAGE_USER);
    if (user) {
      const data = user;
      data.carrinhoAtual = cartId;
      setCryptoLocalStorage(LOCAL_STORAGE_USER, data);
    }
    setLoading(false);
    // setRenderCart((s) => s + 1);
    // setTimeout(() => loadCart(), 300);
  };

  const cartStatus = [
    {
      situacao: 'Cotando',
      id: 0,
      icone: <ShoppingCartIcon className="w-5 h-5" />,
      className: 'text-blue-500',
    },
    {
      situacao: 'Aprovada',
      id: 1,
      icone: <ThumbUpIcon className="w-5 h-5" />,
      className: 'text-green-500',
    },
    {
      situacao: 'Arquivada',
      id: 20,
      icone: <ThumbDownIcon className="w-5 h-5" />,
      className: 'text-gray-500',
    },
    {
      situacao: 'Cancelada',
      id: 70,
      icone: <BanIcon className="w-5 h-5" />,
      className: 'text-red-500',
    },
    {
      situacao: 'Pedido OK',
      id: 90,
      icone: <CheckCircleIcon className="w-5 h-5" />,
      className: 'text-purple-500',
    },
  ];

  const [renderCart, setRenderCart] = useState<number>(0);
  // console.log({ currentCart });

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        currentCart,
        setCurrentCart,
        newCart,
        cleanCart,
        loadCart,
        upsetItemCart,
        deleteItemCart,
        cartStatus,
        updCart,
        updBalcaoComprador,
        renderCart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useCart = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { CartProvider, useCart };
